<template lang="">
    <div>
        <Users/>
    </div>
</template>
<script>
export default {
    components:{
        Users: () => import('@/components/manage/users/Users.vue'),
    }
}
</script>
<style lang="">
    
</style>